import React, { Component } from 'react';
import qs from 'qs';
import Popup from "reactjs-popup"
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Table,Button } from 'react-bootstrap';  
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from "../ModalDemo/Modal"
import { Breadcrumb } from "react-bootstrap";


class HHAname extends Component{
    constructor(props)
    {
      super(props);
      this.state={
       hha_name:'',
       HHAData: [],
       modal: false,
       name: "",
       modalInputName: "",
       Therapist_name:'',
       userData:'',
       new_hha: ''
      }
     
      this.handleSubmit = this.handleSubmit.bind(this);
    };
    componentDidMount() {         
      fetch("https://api.gould-pt.com/api/email/HHAList",{
        
      })
      .then(res=>res.json())
      .then(json=>{
        this.setState({
         HHAData:json,
        })
        console.log(json);
      })
       
        // console.log("data="+HHAname);
    }  

    handleSubmit=(e)=>{
      debugger;
      if(this.refs.hha_name.value=== ""){
           alert("Enter hhaname")
          
      }
      else {      
      fetch('https://api.gould-pt.com/api/Email/InsertHHA' ,
      
        {  
            method: 'POST',
            headers: {
    
            'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body:qs.stringify({
            hha_name :this.refs.hha_name.value,
           }) 
       
        })
        .then(response => {
           alert("Saved Successfully!!");
          fetch("https://api.gould-pt.com/api/email/HHAList",{
        
        })
        .then(res=>res.json())
        .then(json=>{
          this.setState({
           HHAData:json,
          })
          console.log(json);
        })
        
        })
        .catch(error => {
          return alert("Added Successfully")
          
        })  
       //alert("hello")
       fetch("https://api.gould-pt.com/api/email/HHAList",{
        
            })
            .then(res=>res.json())
            .then(json=>{
              this.setState({
               HHAData:json,
              })
              console.log(json);
            })
            
    

  }
 
    }


     handleDelete=(hha_name)=>{
       
        confirmAlert({
          title: 'Delete HHAname',
          message: localStorage.getItem("deleteContact"),    
          buttons: [  
            { 
              label: 'Yes',
               onClick: () => { 
                var Cid=localStorage.getItem("contactID")
                const name = localStorage.getItem('userEmail')
                // var Url = 'https://pwaapi.heyyouwhatsup.com/api/PWA/DeleteContact?currentUserEmail='+name + "&" + "aContactId=" +Cid;
                debugger;
                //alert('https://api.gould-pt.com/api/Email/DeleteHHA?HHAname='+hha_name);
                fetch('https://api.gould-pt.com/api/Email/DeleteHHA?HHAname='+hha_name,
              {
                method:"Post"       
              })
              //  debugger;
              // alert(Url);
              fetch("https://api.gould-pt.com/api/email/HHAList",{
        
            })
            .then(res=>res.json())
            .then(json=>{
              this.setState({
               HHAData:json,
              })
              console.log(json);
            })
            
            }
          },
          
         
            {
              label: 'No',
              
            }
            
          ]
        })
        //window.location.reload();
      }

      toggleModal(hha_name) {  
        localStorage.setItem('hha',hha_name);
        //alert('https://api.gould-pt.com/api/Email/HHANameDetail?name='+this.refs.new_hha);
        
        fetch('https://localhost:44398/api/Email/UpdateHHA',
          {  
            method: 'POST',
            mode:'no-cors',
            headers: {
    
            'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
            
            
          },
          body:qs.stringify({
            oldhha: localStorage.getItem('hha'),
            hha_name :this.state.new_hha.value,
           }) 
       
        })
        .then(response => {
          return ("Successful")
        })
        .catch(error => {
          return   ("Try again");
          
        })  
       //alert("hello")
      window.location.reload(); 
          } 
          
          
          modalOpen(id) {
            console.log("UserId= ",id);
            localStorage.setItem("UserId",id)
            this.setState({ modal: true });
          }
        
          modalClose() {
            this.setState({
              modalInputName: "",
              modal: false
            });
          }
          handleSubmitModal(e) {
            this.setState({ name: this.state.modalInputName });
           // alert('http://localhost:64874/api/email/UpdateHHA'+this.state.modalInputName+localStorage.getItem('UserId'));
            fetch('https://api.gould-pt.com/api/email/UpdateHHA',
          {  
            method: 'Post',
            headers: {
    
            'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body:qs.stringify({
            Id: localStorage.getItem('UserId'),
            hha_name :this.state.modalInputName,
           }) 
        })
        .then(res=>res.json())
          .then(json=>{
            if(json === "Updated")
            {
              alert("Updated Successfully!!")
              fetch("https://api.gould-pt.com/api/email/HHAList",{  
              })
              .then(res=>res.json())
              .then(json=>{
                this.setState({
                HHAData:json,
                })
                console.log(json);
                var {HHAData} = this.state;
                {HHAData.map(userContact=>(
                  console.log("therapistid=",userContact.id)
                ))}

              })
            }
            else
            {
              alert("Try Again!!")
            }
        })
        .catch(error => {
          return   ("Try again");
          
        })

            this.modalClose();
}

          handleChange(e) {
            const target = e.target;
            const name = target.name;
            const value = target.value;
            
        
            this.setState({
              [name]: value
            });
           
          }
      
   render(){
    var {HHAData}= this.state;
    

      return(
          <div>
              {/* <br></br> */}
              <div class="wrapper">
              {/* <div className="page-title">
                    <Breadcrumb>
                    <Breadcrumb.Item href="/Therapist-form">Therapist Form</Breadcrumb.Item>
                    <Breadcrumb.Item active href="/hha-form">HHA Form</Breadcrumb.Item>
                    </Breadcrumb>
                </div> */}
                  <nav class="navbar navbar-expand-md navbar-dark">
                    
                    <div class="mx-auto justify-content-center" id="collapsingNavbar">
                        <ul class="navbar-nav">
                            <li class="">
                                <a class="text-white navbar-brand mr-0 pl-2 " href="" >HHA Form</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                    </div>
                    
                  <div className="container-fluid text-left maxw">
                    {/* <!-- Top navigation --> */}
                <header className="py-3 background text-dark font-weight-bold">
                    <div className="col text-center" style={{fontSize: "18px"}}>
                      <img src="images/logo.png" alt="" className="float-left" />Gould Therapy, LLC</div>
              </header>
{/********************************************** Form ***********************************************/} 
              <div style={{marginTop: "60px"}}> 
              <label htmlFor="name">HHA name</label> 
                    <div className="input-group mb-3">
                      <input type="text" className="form-control border-right-1" 
                         placeholder="HHA Name" 
                         ref="hha_name"
                          name 
                          style={{width: "400px"}}
                      />
                    
                    <button type="submit" onClick={this.handleSubmit.bind(this)}  className="btn btn-danger">Add</button>
                                             
                    </div>
                    <div>  
                    <br></br><br></br>
                    <table className="table table-striped" style={{ marginTop: 10 }}  >  
                      <thead>  
                        <tr>                                
                          <th>Name</th>                            
                          <th colSpan="4">Action</th>

                        </tr>  
                      </thead>  
                      {HHAData.map(userContact=>(
                      <tbody >  
                      {/* { this.tabRow() }    */}
                      
                     <td data-title={userContact.hha_name} >
                       {userContact.hha_name}
                       
                     </td>
                     
                      <td>  
                        {/* <Modal onClick={() => {this.toggleModal(userContact.hha_name) }} /> */}
                      {/* <button type="button"    className="btn btn-info">Edit</button>   */}
                      <a href="javascript:;" onClick={() => {this.modalOpen(userContact.Id) }}>
                          <button
                            type="submit"
                            className="btn btn-info"
                            data-toggle="modal"
                            data-target="#addgroupModal"
                          >
                            Edit
                          </button>
                        </a>
{/*.................................................... HHA MODAL...................................................... */}

                          



  {/*...........................................HHA MODAL ENDS.............................................  */}
                      </td>
                      <td> 
                        <button type="button"  onClick={() => {this.handleDelete(userContact.hha_name) }}  className="btn btn-danger">Delete</button>  
                      </td>     
                      </tbody> 
                      ))} 
                      {/* {HHA_Name.map(hha=>( */}
                      

                    </table>  
                
                  </div>  
                    

              </div>
              </div>
              
              <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
                <h4><b>Edit HHA Name</b></h4>
                <br></br>
                <div lassName="input-group mb-3">
                  <label style={{display: "flex"}}>
                    Enter Name:
                  </label>
                  <input
                    type="text"
                    value={this.state.modalInputName}
                    name="modalInputName"
                    onChange={e => this.handleChange(e)}
                    className="form-control"
                  />
                </div>
                <br></br>
                {/* <div className="row" style={{display: "inline-block", paddingRight: "36px"}}> */}
                <div className="form-group">
                <button onClick={e => this.handleSubmitModal(e)} type="button" className="btn btn-info">
                    Save
                  </button>
                  
                </div>
                {/* </div> */}
              </Modal>
          </div>
      )
  }
}

export default HHAname;