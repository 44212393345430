import logo from './logo.svg';
import './App.css';
import HHAname from './Component/hha-form';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Therapistname from './Component/ManageTherapist';
import Modal from './ModalDemo/AddModal';

function App() {
  return (
    <div className="App">
      <Router> 
          <Route exact path="/" render={() => (
                    <Redirect to="/" />
                )} />
      <Switch>
         <Route exact path="/" component={HHAname}></Route>
         <Route exact path="/Therapist-form" component={Therapistname}></Route>
         <Route exact path="/Modal" component={Modal}></Route>
      </Switch>
    </Router>

    </div>
  );
}

export default App;
